<template>
        <div style="background-color: white;padding: 15px">
            <div style="width: 40%;height: 300px; border: 1px solid rgb(232, 232, 232);  justify-content: space-between;  display: flex;
    flex-direction: column;">
                <div style="display: flex;padding: 10px 15px 0px 15px;">
                    <a-select  v-model="relationship" v-decorator="[ 'Select', { rules: [{ required: true, message: 'Please select your country!' }] }, ]" placeholder="选择" >
                        <a-select-option value="1">
                            男
                        </a-select-option>
                        <a-select-option value="2">
                            女
                        </a-select-option>
                    </a-select>

                    <a-input />
                    <a-input  />
                    <a-input  />
                </div>
                <div>


                     <div style="padding: 0px 15px 0px 15px">
                         <a-input v-model="form.desc" type="textarea" style="border: 0px solid #d9d9d9;height: 180px"/>
                     </div>
                    <div style="display: flex;justify-content: space-between;padding: 15px;align-items: end;">
                        <div>
                            <a-icon type="picture" :style="{ fontSize: '25px', color: '#08c' }"/>
                            <a-icon type="folder" theme="filled" style="margin-left: 5px" :style="{ fontSize: '25px', color: '#08c' }"/>
                        </div>
                        <div>
                            <a-button type="primary">
                            保存
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
            <a-button style="margin-top: 10px;margin-bottom: 10px"> <a-icon type="plus-circle" theme="filled" />添加任务</a-button>
            <a-timeline>
                <a-timeline-item class="timeline" v-for="item in 5">
                    <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                    <span class="time">2015-09-01 12:23:10</span>
                    <div class="content">
                        <div style="display:flex;">
                            <div class="avatar">
                                <a-avatar size="large" icon="user" />
                            </div>
                            <div class="right">
                                <div>王乐</div>
                                <div>标签：请假</div>
                                <div>内容：王乐今天生日</div>
                            </div>
                        </div>
                        <div style="display: flex;align-self: end;">
                            <a-icon type="like" />
                            <a-icon type="message" style="margin-left: 15px" />
                        </div>
                    </div>
                </a-timeline-item>
            </a-timeline>
            <a-pagination
                    show-size-changer
                    :default-current="3"
                    :total="500"
                    @showSizeChange="onShowSizeChange"
            />
        </div>
</template>

<script>
export default {
    data() {
        return {
            pageSize: 20,
            current: 4,
            relationship:'',
            tip:'加载中...',
            show:false,
            delayTime:100,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            },
            config: {
                rules: [{ type: 'object', required: true, message: 'Please select time!' }],
            },

            form: {
                nickname: '',
                sex:'',
                birthday:'',
                schoolId:'',
                intention:'',
                source:'',
                remark:'',
                jionActivity:'',
                contact:{
                    parentName:'',
                    phone:'',
                    relationship:'',
                }
            },
        };
    },
    watch: {
        pageSize(val) {
            console.log('pageSize', val);
        },
        current(val) {
            console.log('current', val);
        },
    },
    methods: {
        onShowSizeChange(current, pageSize) {
            console.log(current, pageSize);
        },
        submit(){
            console.log(this.form)
            let that =this;
            that.show = true
            const axios = require('axios');
            axios.get('https://customer.com/api/student')
                .then(function (response) {
                    // 处理成功情况
                    if(response.data.code==200){
                        that.$message.success('新增成功');
                        that.show=false;
                    }
                })
                .catch(function (error) {
                    // 处理错误情况
                    console.log(error);
                })
                .then(function () {
                    // 总是会执行
                });

        },
        handleChange(){

        },
        handleSubmit(){

        },

    },
}
</script>

<style scoped>
    .timeline{
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .content{
        width: 40%;
        height: 150px;
        padding: 20px;
        display: flex;
        border: 1px solid rgb(232, 232, 232);
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: -14px;
    }
    .avatar{
        width: 50px;
        text-align: center;
        margin-right: 10px;
    }
    .right{
    }
</style>
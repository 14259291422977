<template>
  <div  style="display: flex;">
      <div style="display: flex;padding: 15px;flex-wrap: wrap" v-if="config.searchFields.length>0||config.buttonFields.length>0">
          <div v-for="(item,index) in config.searchFields" v-if="config.searchFields.length>0">
              <span class="labelname">{{item.label}}</span>
              <a-date-picker @change="onChange" v-if="item.type=='date'" @openChange="openChange(index)" class="labelname" v-model="item.value"/>
              <a-select v-if="item.type=='select'" class="labelname" v-model="item.value"  :default-value="item.list[0].label"
                        show-search
                        placeholder="输入内容"
                        option-filter-prop="children"
                        style="width: 200px">
                  <a-select-option :value="it.value" v-for="it in item.list">
                      {{it.label}}
                  </a-select-option>
              </a-select>
              <a-input v-model="item.value" v-if="item.type=='input'" class="labelname" style="width: 150px" :placeholder="item.placeholder"></a-input>
          </div>

          <div style="width: 260px;margin-right: 20px;margin-bottom: 15px;">
              <a-input-search :placeholder="config.placeholder?config.placeholder:'请输入内容'"  enter-button="查询" @search="onButtonMethod('search')"/>
          </div>
<!--          <div style="margin-right: 20px;margin-bottom: 15px;display: flex; border: 1px solid rgb(232, 232, 232);padding: 5px;cursor: pointer;">-->
<!--              <a-icon type="unordered-list" :style="{ fontSize: '23px'}"/>-->
<!--              <a-icon type="compass" theme="filled" :style="{ color: '#69a5ff' ,fontSize: '23px','margin-left':'20px'}" @click="CustomerMap"/>-->
<!--          </div>-->

          <a-button v-if="config.buttonFields.length>0"  v-for="item in config.buttonFields" :type="item.type" :icon="item.icon?item.icon:''" :style="{'margin-right': '20px','margin-bottom': '15px','background-color': item.background_color,'border-color': item.background_color,color:'white'}" @click="onButtonMethod(item.method)">{{item.label}}</a-button>
      </div>
       <slot name="rightSearch"  style="display: flex;justify-content: right" ></slot>
  </div>
</template>
<script>

  export default {
    data() {
      return {
          key:0,
          qTitle:'',
          selectedRowKeys: [],
          activeDateIndex:'',
      };
    },
    methods: {
        //客户地图
        // CustomerMap(){
        //     this.$router.push({ path: '/CustomerMap', query: { Id: 8 }})
        // },
      onButtonMethod(method){
        let that = this;
        if(method==='search'){
            that.buttonMethod(method,that.config.searchFields)
        }else{
            that.buttonMethod(method)
        }
      },
      onChange(date,dateString) {
          let that = this;
          that.config.searchFields[that.activeDateIndex].value= dateString
      },
      openChange(index){
          let that = this;
          that.activeDateIndex = index;
      }
    },
    props:{
        buttonMethod: {
            type: Function,//定义方法
            default: function() {
                console.warn('页面未定义buttonMethod方法')
            }
        },
        config: {
            type: Object,
            default: () => {
              return {
                  placeholder:'请输入内容',
                  searchFields:[
                    // { title: 'Full Name', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
                    // { title: 'Age', width: 100, dataIndex: 'age', key: 'age', fixed: 'left' },
                    // { title: 'Column 1', dataIndex: 'address', key: '1' },
                  ],
                  buttonFields:[],


              }
            },
      },
    },
  };
</script>
<style>
    .labelname{
        margin-right: 10px;
    }
</style>
<template>
  <div style="display: flex;flex-direction: column;">
    <a-tabs type="card" @change="onChangeTab" v-show="config.tabsConfig.length>0" :size="config.tabSize?config.tabSize:'default'">
      <a-tab-pane :key="index" :tab="item" v-for="(item,index) in config.tabsConfig"></a-tab-pane>
    </a-tabs>
    <search :config="config.searchConfig" :buttonMethod="onButtonMethod" v-if="config.searchConfig.searchFields.length>0||config.searchConfig.buttonFields.length>0">
    </search>
<!--   设置x: 1100 要大于1000px-->
      <div style="width: 1750px">
        <a-table
                v-show="config.tableConfig.columns.length>0"
                :columns="config.tableConfig.columns"
                :data-source="config.tableConfig.data"
                :scroll="{ x: '2000px',y:530 }"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                bordered
                @change="onChangePage"
                :pagination="config.pageConfig"
        >
            <a slot="action" slot-scope="text">action</a>
          <span slot="name" slot-scope="text, record">
             <span style="color: rgb(76 169 249)">{{ record.name }}</span>
<!--            <a-icon type="woman" :style="{ fontSize: '12px','margin-left':'3px', color: '#ffffff','background-color': 'rgb(8 164 255)',padding:'2px','border-radius':'8px', }"/>-->
            <a-icon type="man" :style="{ fontSize: '12px','margin-left':'3px', color: '#ffffff','background-color': 'rgb(8 164 255)',padding:'2px','border-radius':'8px', }"/>
          </span>
        </a-table>
      </div>
    <slot name="form" v-if="config.formConfig.component"></slot>
  </div>
</template>
<script>
  import Search from './Search'
  export default {
      components: {
          Search,
      },
    data() {
      return {
          key:0,
          selectedRowKeys: [],
          tableWidth:'1500px',
      };
    },
    methods: {
          //翻页传参
          onChangePage(){

          },
          onSelectChange(selectedRowKeys) {
              let that = this;
              console.log('selectedRowKeys changed: ', selectedRowKeys);
              that.selectedRowKeys = selectedRowKeys;
          },
          onChangeTab(key) {
              let that = this;
              that.key = key;
              that.changeTab(key)
          },
          onButtonMethod(value,array){
              let that = this;
              that.buttonMethod(value,array)
          }
    },
    props:{
        changeTab: {
            type: Function,//定义方法
            default: function() {
                console.warn('未传递changeTab方法')
             }
        },
        buttonMethod: {
            type: Function,//定义方法
            default: function() {
                console.warn('页面未定义buttonMethod方法')
            }
        },
        config: {
            type: Object,
            default: () => {
              return {
                tabSize:'',
                tabsConfig:[],
                searchConfig:[],
                tableConfig:{
                    columns:[],
                    data:[],
                },
                formConfig:{
                    currentComponent:'',
                },
                pageConfig:{},
              }
            },
      },
    },
  };
</script>
<style scoped>
  /deep/.ant-table-thead > tr > th{
    background-color: #ededed;
    border-right: 1px solid #dfdfdf;
  }
</style>
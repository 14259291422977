<template>
        <div style="background-color: white;padding: 15px">
            <a-timeline>
                <a-timeline-item class="timeline">
                    <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                    <span class="time">2015-09-01 12:23:10</span>
                    <div class="content">
                        <div class="avatar">
                            <a-avatar size="large" icon="user" />
                        </div>
                        <div class="right">
                            <div>王乐</div>
                            <div>标签：请假</div>
                            <div>内容：王乐今天生日</div>
                        </div>
                    </div>
                </a-timeline-item>

                <a-timeline-item class="timeline">
                    <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                    <span class="time">2015-09-01 12:23:10</span>
                    <div class="content">
                        <div class="avatar">
                            <a-avatar size="large" icon="user" />
                        </div>
                        <div class="right">
                            <div>王乐</div>
                            <div>标签：请假</div>
                            <div>内容：王乐今天生日</div>
                        </div>
                    </div>
                </a-timeline-item>

                <a-timeline-item class="timeline">
                    <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                    <span class="time">2015-09-01 12:23:10</span>
                    <div class="content">
                        <div class="avatar">
                            <a-avatar size="large" icon="user" />
                        </div>
                        <div class="right">
                            <div>王乐</div>
                            <div>标签：请假</div>
                            <div>内容：王乐今天生日</div>
                        </div>
                    </div>
                </a-timeline-item>

                <a-timeline-item class="timeline">
                    <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                    <span class="time">2015-09-01 12:23:10</span>
                    <div class="content">
                        <div class="avatar">
                            <a-avatar size="large" icon="user" />
                        </div>
                        <div class="right">
                            <div>王乐</div>
                            <div>标签：请假</div>
                            <div>内容：王乐今天生日</div>
                        </div>
                    </div>

                </a-timeline-item>

                <a-timeline-item class="timeline">
                    <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                    <span class="time">2015-09-01 12:23:10</span>
                    <div class="content">
                        <div class="avatar">
                            <a-avatar size="large" icon="user" />
                        </div>
                        <div class="right">
                            <div>王乐</div>
                            <div>标签：请假</div>
                            <div>内容：王乐今天生日</div>
                        </div>
                    </div>
                </a-timeline-item>

                <a-timeline-item class="timeline">
                    <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                    <span class="time">2015-09-01 12:23:10</span>
                </a-timeline-item>

            </a-timeline>
        </div>
</template>

<script>
export default {
    data() {
        return {
            tip:'加载中...',
            show:false,
            delayTime:100,
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 8 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 16 },
                },
            },
            config: {
                rules: [{ type: 'object', required: true, message: 'Please select time!' }],
            },

            form: {
                nickname: '',
                sex:'',
                birthday:'',
                schoolId:'',
                intention:'',
                source:'',
                remark:'',
                jionActivity:'',
                contact:{
                    parentName:'',
                    phone:'',
                    relationship:'',
                }
            },
        };
    },
    methods: {
        submit(){
            console.log(this.form)
            let that =this;
            that.show = true
            const axios = require('axios');
            axios.get('https://customer.com/api/student')
                .then(function (response) {
                    // 处理成功情况
                    if(response.data.code==200){
                        that.$message.success('新增成功');
                        that.show=false;
                    }
                })
                .catch(function (error) {
                    // 处理错误情况
                    console.log(error);
                })
                .then(function () {
                    // 总是会执行
                });

        },
        handleChange(){

        },
        handleSubmit(){

        },

    },
}
</script>

<style scoped>
    .timeline{
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .content{
        width: 40%;
        height: 150px;
        padding: 20px;
        display: flex;
        border: 1px solid rgb(232, 232, 232);
        border-radius: 5px;
    }
    .avatar{
        width: 50px;
        text-align: center;
        margin-right: 10px;
    }
    .right{
    }
</style>
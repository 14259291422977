<template>
    <div>
        <a-modal v-model="config.visible" :title="config.title" @ok="handleOk()" @cancel="handleCancel" centered>
            <a-form :model="config.form" :label-col="labelCol" :wrapper-col="wrapperCol" >
                <a-form-item :label="item.label" v-for="(item,index) in config.form" v-if="config.form.length>0">
                    <a-select v-if="item.type=='select'" class="labelname" v-model="item.value"
                              show-search
                              placeholder="输入场景"
                              option-filter-prop="children"
                              style="width: 100%">
                        <a-select-option :value="it.value" v-for="it in item.list">
                            {{it.label}}
                        </a-select-option>
                    </a-select>
                    <a-date-picker @change="onChange" v-if="item.type=='date'" @openChange="openChange(index)"  style="width: 100%" class="labelname" v-model="item.value"/>
                    <a-input v-model="item.value" v-if="item.type=='input'" class="labelname" style="width: 100%" :placeholder="item.placeholder"></a-input>
                    <a-input v-model="item.value" v-if="item.type=='textarea'" class="labelname" type="textarea" style="width: 100%" :placeholder="item.placeholder"></a-input>
                    <a-checkbox @change="onChange" v-if="item.type=='checkbox'">
                        {{item.label}}
                    </a-checkbox>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
export default {
    props:{
        config: {
            type: Object,
            default: () => {
                return {
                    title:'',
                    visible:false,
                    method:'',
                    form:[],
                }
            },
        },
        submit: {
            type: Function,//定义方法
            default: function() {
                console.warn('页面未定义submit方法')
            }
        },
    },
    data() {
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            activeDateIndex:'',
        };
    },
    methods: {
        handleOk() {
            let that = this;
            let res = that.submit(that.config)
            console.log(res)
            if(res){
                that.config.visible = false;
            }

        },
        handleCancel(){
            let that = this;
            that.config.visible = false;
        },
        onChange(date,dateString) {
            let that = this;
            that.config.form[that.activeDateIndex].value= dateString
        },
        openChange(index){
            let that = this;
            that.activeDateIndex = index;
        }
    },
}
</script>

<style scoped>
    .labelname{
        margin-right: 10px;
    }
    /deep/.ant-modal-footer{
        text-align: center ;
    }
</style>